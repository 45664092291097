import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import { getValFromLS, setValToLS, fmtCurrency } from "../../utils"
import S from "./styled"
import { Table } from "../../styles/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faTrashAlt } from "@fortawesome/pro-light-svg-icons"

S.Table = Table

const Order = ({ member }) => {
  const [items, setItems] = useState([])

  let shipping = 0
  let discount = 0
  let subtotal = 0

  useEffect(() => {
    if (getValFromLS("order", true)) {
      setItems(JSON.parse(getValFromLS("order", true)))
    }
  }, [])

  const handleRemove = index => {
    let array = items
    array.splice(index, 1)

    setItems([...array])
    setValToLS(`order`, JSON.stringify(array), true)
  }

  return (
    <S.Container>
      {items.length === 0 ? (
        <Fragment>
          <div className="empty">
            <FontAwesomeIcon icon={faTimesCircle} size="lg" />
            <span>Your cart is currently empty.</span>
          </div>

          <div className="buttons">
            <Link to="/" className="button">
              Back to Courses
            </Link>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <S.Table>
            <thead>
              <tr>
                <th className="left">Course Details</th>
                <th>Format</th>
                <th className="price">
                  {member.number ? "Member" : "Non Member"}
                </th>
              </tr>
            </thead>

            <tbody>
              {items.map((item, index) => {
                if (item.format === "cd" || item.format === "dvd") {
                  shipping = 1000
                }
                const amt = member.number ? item.member : item.nonmember
                subtotal = subtotal + amt
                /* discount = member.number ? discount + amt * 0.25 : 0*/

                return (
                  <tr key={index}>
                    <td>
                      <div className="item">
                        <div>
                          <Link to={`/course/${item.id}`}>{item.name}</Link>
                          <ul>
                            <li>{`Expires: ${item.expires}`}</li>
                          </ul>
                        </div>

                        <button
                          type="button"
                          className="naked"
                          onClick={() => handleRemove(index)}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            size="lg"
                            className="error"
                          />
                        </button>
                      </div>
                    </td>
                    <td className="center">{item.format.toUpperCase()}</td>
                    <td className="right">{fmtCurrency(amt / 100)}</td>
                  </tr>
                )
              })}
            </tbody>

            <tfoot>
              <tr>
                <th className="right" colSpan="2">
                  Subtotal
                </th>
                <td className="right">{fmtCurrency(subtotal / 100)}</td>
              </tr>

              {/*member.number && (
                <tr>
                  <th className="right" colSpan="2">
                    25% Off Courses (7/15 - 8/15)
                  </th>
                  <td className="right">
                    <span style={{ color: "var(--error)" }}>
                      ({fmtCurrency(discount / 100)})
                    </span>
                  </td>
                </tr>
              )*/}

              <tr>
                <th className="right" colSpan="2">
                  Shipping
                </th>
                <td className="right">{fmtCurrency(shipping / 100)}</td>
              </tr>

              <tr>
                <th className="right" colSpan="2">
                  Total
                </th>
                <td className="right">
                  {fmtCurrency((subtotal - discount + shipping) / 100)}
                </td>
              </tr>
            </tfoot>
          </S.Table>

          <div className="buttons">
            <Link to="/" className="button">
              Back to Courses
            </Link>
            <Link to="/review" className="button">
              Checkout
            </Link>
          </div>
        </Fragment>
      )}
    </S.Container>
  )
}

export default Order
