import styled from "styled-components"

let S = {}
S.Container = styled.div`
  padding: 25px;

  & .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--error);

    & > span {
      padding-top: 7.5px;
    }
  }

  & .price {
    width: 15%;
  }

  & .item {
    padding: 7.5px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 649px) {
      flex-direction: column;
      justify-content: center;
    }

    & > div {
      display: flex;
      flex-direction: column;
      padding-right: 15px;

      @media only screen and (max-width: 649px) {
        padding-bottom: 7.5px;
      }

      & a {
        font-size: 0.9rem;
        font-weight: bold;
      }

      & ul {
        margin: 0;
        padding: 0;
        margin-top: 5px;
        margin-left: 30px;
        font-size: 0.85rem;
      }
    }

    & .error {
      font-size: 1.2rem;
      color: var(--error);

    }
  }

  & > .buttons {
    margin: 0 auto;
    margin-top: 25px;
    display: flex:
    align-items: center;
    justify-content: center;

    & > a {
      display: block;
      width: 200px;
    }

    & > a:first-child {
      margin-right: 7.5px;
    }

    & > a:last-child {
      margin-left: 7.5px;
    }
  }
`

export default S
