import styled from "styled-components"

let S = {}
S.Grid = styled.div`
  border-bottom: 1px solid var(--border);
  margin: 0 auto;
  padding: 15px 25px 25px 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media only screen and (max-width: 649px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  & > .left {
    & h2 {
      font-size: 1.35rem;
      margin-bottom: 12.5px;
      color: var(--blue);
    }

    & p {
      font-size: 0.95rem;
      margin-bottom: 12.5px;

      & > button {
        margin-left: 12.5px;
      }
    }

    & ul {
      font-size: 0.9rem;

      & > li {
        padding-bottom: 10px;
      }

      & > li:last-child {
        padding-bottom: 0;
      }
    }
  }

  & > .right {
    margin-top: 25px;

    @media only screen and (max-width: 649px) {
      margin-top: 0;
    }

    & > div {
      display: flex;

      & > div.icon-container {
        text-align: center;
        width: 65px;
      }

      & p.hdg {
        font-size: 1rem;
        margin-bottom: 5px;
        color: var(--blue);
        font-weight: 600;
      }

      & p {
        font-size: 0.85rem;
        margin-bottom: 12.5px;
      }
    }

    & > div:last-child {
      margin-top: 5px;
      padding-top: 25px;
      border-top: 1px solid var(--border);
    }

    & .red {
      color: var(--error);
    }
  }
`

export default S
