import React, { useState, useEffect, Fragment } from "react"
import { getValFromLS, setValToLS } from "../utils"
import Helmet from "../components/Helmet"
import Hdr from "../components/Hdr"
import Member from "../pagesCart/Member"
import Order from "../pagesCart/Order"
import Ftr from "../components/Ftr"

const CartPage = () => {
  const [signedin, setSignedin] = useState({})

  useEffect(() => {
    if (getValFromLS("member", true)) {
      setSignedin(JSON.parse(getValFromLS("member", true)))
    }
  }, [])

  const handleSignin = obj => {
    if (obj.number) {
      setValToLS("member", JSON.stringify(obj), true)
      setSignedin({ ...obj })
    } else {
      setValToLS("member", null, true)
      setSignedin({})
    }
  }

  return (
    <Fragment>
      <Helmet
        title="Cart"
        descr="The Palm Beach County Bar Association (PBCBA) offers CLE hours from the sale of audio MP3’s, MP4’s and CD’s recorded at previously held live seminars of area bar associations."
      />
      <Hdr />
      <Member member={signedin} signin={handleSignin} />
      <Order member={signedin} />
      <Ftr />
    </Fragment>
  )
}

export default CartPage
