import React, { useState, useRef, Fragment } from "react"
import { API_URL } from "../../utils"
import Input from "../../components/Input"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import { Form, FormRow1, FormMsgSubmit } from "../../styles/styled"
import S from "./styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

S.Form = Form
S.FormRow1 = FormRow1
S.FormMsgSubmit = FormMsgSubmit

const Member = ({ member, signin }) => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/member`)
    /*
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      data.append(key, value)
    })
    */

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(names),
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        signin({ ...json })
        setMsg({})
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Fragment>
      {member.number ? (
        <S.Grid style={{ paddingBottom: "15px" }}>
          <div className="left">
            <h2>PBCBA Members</h2>
            <p style={{ marginBottom: "5px" }}>
              <span>Welcome, {`${member.fname}`}.</span>
              <button
                type="button"
                className="button"
                onClick={() => signin({})}
              >
                Sign Out
              </button>
            </p>
          </div>
        </S.Grid>
      ) : (
        <S.Grid>
          <div className="left">
            <h2>PBCBA Members</h2>
            <p>
              If you are a current PBCBA member in good standing, please enter
              your <strong>Florida Bar Number</strong> to take advantage of our
              reduced member pricing.
            </p>

            <ul>
              <li>
                Please note: If your Florida Bar Number is not found, please
                contact the Bar Association with questions, it may be a matter
                of extra 0’s in your number.
              </li>
              <li>
                Questions? Please email us at:{" "}
                <a href="mailto:dloiseau@palmbeachbar.org">
                  dloiseau@palmbeachbar.org
                </a>
                .
              </li>
            </ul>
          </div>

          <div className="right">
            <S.Form
              method="post"
              action="/"
              onSubmit={e => handleSubmit(e)}
              ref={formElement}
            >
              <S.FormRow1>
                <Input
                  name="number"
                  label="Florida Bar Number"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                />
              </S.FormRow1>

              <S.FormMsgSubmit>
                {msg.type && <Msg data={msg} />}
                {msg.type !== "working" && (
                  <Submit name="Sign In" icon={faChevronCircleRight} />
                )}
              </S.FormMsgSubmit>
            </S.Form>
          </div>
        </S.Grid>
      )}
    </Fragment>
  )
}

export default Member
